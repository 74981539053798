body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 700px) {
  .listOuter {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .listOuter {
    width: 90%;
  }
}
@media (min-width: 800px) {
  .listOuter {
    width: 70%;
  }
}

.listOuter {
  margin: auto;
  padding: 20px;
}

.listInner {
  text-align: left;
}

.task {
  margin: 5px;
  padding: 20px;
  border: 1px solid gray;
  border-radius: 5px;
}

.group {
  margin: 5px;
  padding: 20px;
  border: 1px solid gray;
  border-radius: 5px;
}

.group_header {
  margin: 2px;
  padding-left: 24px;
  border: 1px solid darkgreen;
  background-color: lightgreen;
  color: darkgreen;
  border-radius: 5px;
}

.strike {
  color: gray;
  text-decoration: line-through;
  background-color: lightblue;
  border: 1px solid darkblue;
}

.task_options {
  float: right;
  text-align: right;
  margin-left: auto;
  margin-right: 0;
}

.task_task {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.task_task_hovered {
  white-space: normal;
  overflow: auto;
  text-overflow: inherit;
}

.spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  /* transform: rotate(3deg); */
  /* transform: rotate(0.3rad);/ */
  /* transform: rotate(3grad); */
  /* transform: rotate(.03turn);  */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.task_title {
  font-size: 18px;
}
.task_location {
  font-size: 18px;
}
.task_resolution {
  font-size: 18px;
}
.task_explanation {
  font-size: 18px;
}
.task_topic {
  font-size: 22px;
}

.full-height {
  height: 100%;
}

@media only screen and (min-width: 910px) {
  .full-height button {
    height: 100%;
  }
}
@media only screen and (max-width: 910px) {
  .full-height button {
    height: 32px;
    padding: 0;
    margin: 5px;
  }
}
